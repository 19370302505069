const React = require("react")
const Layout = require("./src/components/layouts/Layout").default

require("@fontsource/montserrat")
require("@fontsource/montserrat/100.css")
require("@fontsource/montserrat/200.css")
require("@fontsource/montserrat/300.css")
require("@fontsource/montserrat/500.css")
require("@fontsource/montserrat/600.css")
require("@fontsource/montserrat/700.css")
require("@fontsource/montserrat/800.css")
require("@fontsource/montserrat/900.css")
require('./src/styles/global.scss')

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}