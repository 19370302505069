import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa'

const WhatsAppBubble = ({ number }) => {
  if (!number) return null

  return (
    <StyledBubble 
      href={`https://wa.me/+27${number.replaceAll(' ', '')}`}
      target="_blank"
      rel="noreferrer"
      aria-label="Chat on WhatsApp"
    >
      <FaWhatsapp />
    </StyledBubble>
  )
}

const StyledBubble = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  transition: transform 0.2s;
  z-index: 1000;

  svg {
    font-size: 2.5em;
    margin: 0;
  }

  &:hover {
    transform: scale(1.1);
    color: white;
  }

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
    
    svg {
      font-size: 2em;
    }
  }
`

export default WhatsAppBubble